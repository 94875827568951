
import { Component, Watch } from 'vue-property-decorator'
import Emitter from '@/utils/emitter'
import { formatDate } from '@/utils/date'

@Component({
	filters: {
		formatDate(value: number) {
			return value ? formatDate('yyyy-MM-dd h:i:s', value) : '-'
		}
	}
})
export default class extends Emitter {
	private searchForm = {
		packageCode: '',
		userCode: ''
	}

	private pageConfig = {
		total: 0,
		size: 20,
		current_page: 1
	}
	@Watch('pageConfig.current_page')
	onPagination() {
		this.search()
	}

	private list: any = []
	created() {
		const { size, current_page }: any = this.$route.query
		this.pageConfig.size = Number(size || 20)
		this.pageConfig.current_page = Number(current_page || 1)
		this.search(true)
	}
	resetSearch() {
		this.searchForm.packageCode = ''
		this.searchForm.packageCode = ''
		this.search(true)
	}
	async search(reset?: boolean) {
		if (reset) {
			this.pageConfig.current_page = 1
		}
		const { packageCode, userCode } = this.searchForm
		const { size, current_page } = this.pageConfig

		const { data } = await this.$axios
			.get('/v1/jobline/return/package-attr', {
				params: {
					user_code: userCode,
					package_code: packageCode,
					page_size: size,
					page_index: current_page
				}
			})
			.catch(e => {
				return { data: null }
			})

		this.$router
			.replace({
				query: {
					package_code: packageCode,
					user_code: userCode,
					size: String(size),
					current_page: String(current_page)
				}
			})
			.catch(err => err)
		this.list = data.list
		this.pageConfig.total = data.page.total
	}

	async reject(id: number) {
		await this.$prompt('拒绝理由', '提示', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			inputPlaceholder: '请输入拒绝理由',
			inputValidator: (value: string) => {
				if (!value) {
					return '请输入拒绝理由'
				}
				if (value.trim().length < 2) {
					return '理由不能少于2个字符'
				}
				return true
			}
		})
			.then(async ({ value }: any) => {
				await this.$axios
					.post('/v1/jobline/return/package-attr', {
						id,
						type: 2,
						reason: value
					})
					.then(() => {
						this.$message.success('操作成功')
						this.search()
					})
			})
			.catch(() => {
				console.log('cancel')
			})
	}

	async agree(id: number) {
		await this.$axios
			.post('/v1/jobline/return/package-attr', {
				id,
				type: 1,
				reason: '截单成功'
			})
			.then(() => {
				this.$message.success('截单成功')
			})
			.finally(() => this.search())
	}
}
